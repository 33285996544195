//
// Blog.scss
//

.blog-box{
  .blog-date{
    width: 50px;
    background: $primary;
    text-align: center;
  }

  
}
