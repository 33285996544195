  //
// menu.scss
//
@import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.navbar-custom {
    padding: 0px 0px;
    width: 100%;
    border-radius: 0px;
    z-index: 999;
    margin-bottom: 0px;
    transition: all 0.5s ease-in-out;
    background: #00000082;

    .navbar-nav {
        li {
            a {
                line-height: 26px;
                color: rgba($white, 0.6);
                font-size: 14px;
                text-transform: capitalize;
                transition: all 0.5s;
                background-color: transparent !important;
                padding: 6px 0;
                margin: 0 10px;
                font-weight: 500;
            }
        }
    }
}


.navbar-toggler {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: $dark;
}

.logo {
    .logo-light {
        display: inline-block;
    }
    .logo-dark {
        display: none;
    }
}

.nav-sticky {
    .logo {
        .logo-dark {
            display: inline-block;
        }
        .logo-light {
            display: none;
        }
    }
}

.navbar-custom .navbar-nav li.active a,
.navbar-custom .navbar-nav li a:hover,
.navbar-custom .navbar-nav li a:active {
    color: $white !important;
}

.navbar-toggle {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: $white;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: transparent;
    border-color: #337ab7;
}

.navbar-custom {
    .navbar-toggles {
        padding: .25rem .75rem;
        font-size: 18px;
        background: 0 0;
        border: 1px solid transparent;
        color: $white;
        outline: 0;
    }

    .navbar-toggles-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
    }
}

.menu-toggle {
    padding: 4.5px 10px !important;
}

.menu-toggle {
    span {
        line-height: 27px;
    }
}

.navbar-button{
    margin-left: 20px;
}


/*---NAVBAR STICKY--*/

.nav-sticky{
    &.navbar-custom {
        margin-top: 0px;
        padding: 15px 0px;
        background-color: $white;
        box-shadow: 0 10px 33px rgba(140, 140, 140, 0.1);

        &.sticky-dark {
            background-color: $white;
        }
    }
    .logo {
        color: $dark !important;
    }

    .navbar-nav {
        li {
            a {
                color: $dark !important;
            }
        }
    }
}


.nav-sticky.navbar-custom .navbar-nav li.active a,
.nav-sticky.navbar-custom .navbar-nav li a:hover,
.nav-sticky.navbar-custom .navbar-nav li a:active {
    color: #ed1c24 !important;
}

.nav-sticky.navbar-custom .navbar-toggles {
    padding: .25rem .75rem;
    border: 1px solid transparent;
    outline: 0;
}
.nav-sticky .navbar-nav {
    margin-top: 0px;
}
.navbar-custom .navbar-nav li a {
    line-height: 26px;
    color: rgb(255 255 255);
    font-size: 14px;
    text-transform: capitalize;
    transition: all 0.5s;
    background-color: transparent !important;
    padding: 6px 0;
    margin: 0 10px;
    font-weight: 500;
    letter-spacing: 2px;
}
 .container {
    max-width: 1440px;
}
.nav-sticky.navbar-custom {
    margin-top: 0px;
    padding: 0px 0px;
    background-color: #fff !important;
    box-shadow: 0 10px 33px rgb(140 140 140 / 10%);
}
.home-section {
    background-image: url('../images/bannerback.png');
    height: 100VH;
    display: flex;
    justify-content: center;
    align-items: center;
    background-attachment: fixed;
    background-position: inherit;
   
  }
  .bannercenter h1 {
    color: #ffffffc7;
    font-weight: 400;
    font-size: 37px;
    height: 60px;
    overflow: hidden;
    font-family: "Philosopher", sans-serif;
    letter-spacing: 1px;
}
.bannercenter h1 span {
    font-weight: bolder;
    position:relative;
    color: #fff;
    animation-duration: 2s;
    animation-name: bannercenterspan;
   
}
@keyframes bannercenterspan{
    0% {top:-100px}
    100%{top: 0px;}

}
.bannercenter h1 img {
    width: 55px;
    margin-right: 8px;
    animation-duration: 2s;
    animation-name: h1img;
    position:relative;
}
@keyframes h1img{
    0% {left:-1000px}
    100%{left: 0px;}

}
.bannercenter h1 strong {
    
    animation-duration: 5s;
    animation-name: bannercenterstrong;
    color: #ff161e;
    font-size: 40px;
}
.home-section .topimg {
    position: absolute;
    right: 0;
    top: 0;
    animation-duration: 2s;
    animation-name: mymove;
   
}
.bannercenter {
    padding-top: 13%;
}
 .cardimg {
    
    overflow: hidden;
}
.cardimg img{
    animation-duration: 2s;
    animation-name: cardimg;
    position:relative;
}

@keyframes cardimg {
    0% {bottom: -1000px;}
    100%{bottom: 0px;}
  }


@keyframes bannercenterstrong{
    0% {color:#fff}
    100%{color: #ff161e;}

}

@keyframes mymove {
    0% {right: -300px;}
  50% {right: 0px;}
  75% {top: 40px;}
  100%{top: 0px;}
  }

.buttomimg {
    position: absolute;
    left: 0;
    bottom: 0;
    animation-duration: 3s;
    animation-name: slidein;
   
}
@keyframes slidein {
    0% {    
        left: -300px;
    }
    100% {
      left: 0px;
    }
  }

.blog-img {
    height: 500px;
    background-size: cover !important;
    position: relative;
    transition: all 0.6s linear 0s;
    border-bottom-left-radius: 40px;
    border-top-right-radius: 40px;
}
.blog-img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.blog_text {
    position: absolute;
    bottom: 0;
    background: #ffffffab;
    width: 100%;
    padding: 16px 0px;
}
.blog_text h5 {
    margin: 0px;
    height: auto !important;
    font-size: 22px;
    color: #000;
}
section#blog h2 {
    font-size: 48px;
    font-weight: 400;
    color: #000;
    font-family: 'Philosopher', sans-serif;
    letter-spacing: 1px;
}
section#blog .header-title .title-border {
    width: 260px;
    background: linear-gradient(to right, #ed1c2400, #ed1c24, transparent);
}
section#blog {
    padding-bottom: 0;
}
.becometext h2 {
    font-size: 27px;
    text-align: left;
    font-family: 'Philosopher', sans-serif;
    font-weight: 600;
}

.becometext .header-title .title-border {
    margin: inherit;
    background: linear-gradient(to right, #ed1c2400, #ed1c24, transparent);
    width: 109px;
}
.becometext p.title-desc {
    text-align: left;
    margin: 0px;
    font-size: 16px !important;
    letter-spacing: 1px;
    line-height: 38px;
}
.contactfrom {
    background: #fff;
    border: 1px solid #dfdfdfcc;
    padding: 50px 20px 20px 40px;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
}

.contactfrom h1.title {
    font-size: 31px;
    letter-spacing: 2px;
    color: #c5262e;
    font-family: 'Philosopher', sans-serif;
    font-weight: 600;
}
.bogosection .lft h2 {
    font-family: 'Philosopher', sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
}
.contactfrom input {
    height: auto !important;
    border: 1px solid #cccccc4a;
    padding: 14px 16px;
    background: #f9f9f9ad;
    border-radius: 0px;
    letter-spacing: 2px;
}
.contactfrom label {
    font-size: 14px !important;
    letter-spacing: 1px;
}
.contactfrom select.form-control {
    height: auto;
    border: 1px solid #cccccc4a;
    padding: 14px 16px;
    background: #f9f9f9ad;
    border-radius: 0px;
    letter-spacing: 2px;
}
.contactfrom button#submit {
    float: right;
    padding: 10px 25px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
}
.benefits .text-muted {
    font-size: 16px;
    letter-spacing: 6px;
}
.benefits h2 {
    font-size: 48px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #000;
    font-family: 'Philosopher', sans-serif;

}
.benefits .title-border {
    width: 180px;
    background: linear-gradient(to right, #ed1c2400, #ed1c24, transparent);
}
.about-box {
    padding: 40px 69px !important;
    height: 336px !important;
    background: #f5f5f5 !important;
    box-shadow: 0px 14px 10px #0000001c;
}
.about-box i {
    font-size: 62px !important;
    background: #cccccc26;
    width: 100px;
    height: 100px;
    padding: 10px 16px;
    border-radius: 100px;
    transition: all 0.6s linear 0s;
}
.about-box:hover i {
    background: #ed1c24;
    color: #fff !important;
}
.about-box:hover {
    transition: all 0.3s linear 0s;
    box-shadow: 0px 14px 10px #00000000;
    cursor: pointer;
}
.about-box h5 {
    font-size: 22px  !important;
    font-weight: 400;
    height: 40px;
}
.about-box p.text {
    font-size: 14px  !important;
    letter-spacing: 1px;
}
.bg-progress h2 {
    font-size: 48px;
    letter-spacing: 1px;
    font-weight: 400;
    font-family: 'Philosopher', sans-serif;
}
.bg-progress .title-border {
    background: linear-gradient(to right, #ed1c2400, #ffffff, transparent);
    width: 180px;
}

.bg-progress .progress-count h3 {font-size: 50px;
    font-weight: 100;
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress-content h5 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 1px;
    height: 50px;
    margin-top: 2rem !important;
}

.progress-content .text-muted {
    color: #ffffffcf !important;
    line-height: 20px;
    letter-spacing: 1px;
}
.progress-content {
    position: relative;
    height: 190px !important;
    background-color: #ffffff2b !important;
    padding: 21px 30px !important;
    margin-top: 1rem !important;
}
.progress-content:before {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    background-color: #ed1c24 !important;
    transform: rotate(
45deg);
    left: 0;
    right: 0;
    margin: 0px auto;
    margin-top: -35px;
}
.bg-progress button.btn.btn-primary {
    position: relative;
    margin: 0 auto;
    display: flex;
    top: 50px;
    letter-spacing: 2px;
}
.bogosection {
    clear: both;
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bogosection .lft {
    width: 60%;
}

.bogosection .rgt {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6%;
}
.bogosection:after {content: "";
    display: block;
    clear: both;
}
.bogosection .rgt h4 {
    margin: 0px;
    font-family: 'Philosopher', sans-serif;
}
.bogosection .container {
    display: flex;
    align-items: center;
}
.pricing h2 {
    font-size: 48px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: 'Philosopher', sans-serif;
}
.pricing .text-muted {
    font-size: 12px;
    letter-spacing: 4px;
}
.pricing .title-border {
    background: linear-gradient(to right, #ed1c2400, #ed1c24, transparent);
    width: 100px;
    position: relative;
    top: -12px;
}
.pricing .title-desc {
    max-width: 100%;
    letter-spacing: 2px;
}
.pricing-box .textsection {
    padding: 30px 30px;
}
.pricing-box .textsection h5 {
    text-align: center;
    font-size: 40px;
    font-weight: bolder;
    color: #cd131c;
    text-shadow: 1px 1px #00000024;
    letter-spacing: 1px;
    font-family: 'Philosopher', sans-serif;
}
.pricing-box {
    box-shadow: 0px 14px 10px #0000001c;
}
.pricing-box .textsection h1 {
    font-size: 36px !important;
    text-align: center;
    font-family: 'Philosopher', sans-serif;
}

.pricing-box .textsection h1 sup.h3 {
    color: #4ca509;
    font-size: 26px;
}
.pricing-box .pricing-features p img {
    width: 16px;
    margin-right: 16px;
}
.pricing-box .pricing-features p {
    letter-spacing: 1px;
    font-size: 12px !important;
}
section#portfolio h2 {
    font-size: 48px;
    letter-spacing: 1px;
    font-weight: 400;
    font-family: 'Philosopher', sans-serif;
}

section#portfolio .title-border {
    background: linear-gradient(to right, #ed1c2400, #ed1c24, transparent);
    width: 350px;
}

section#portfolio .title-desc {
    max-width: 100%;
    letter-spacing: 2px;
}
.cbox-gallary1 img {
    width: 240px;
    height: 240px;
       margin-bottom: 40px;
}
input:focus {
    outline: none !important;
}
.bogosection .lft img {
    float: left;
    width: 99px;
    margin-right: 20px;
}
.portfoliobox {
    text-align: center;
    border: 1px solid #cccccc38;
    margin: 6px 6px;
    max-width: 32.333333%;
    background: #cccccc0f;
    transition: all 0.6s linear 0s;
}
.portfoliobox:hover {
    background: #cccccc61;
}
.portfoliobox h5 {
    font-size: 20px;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.testimonial-box .testimonial-title {
    max-width: 80% !important;
   
    background: none !important;
    letter-spacing: 2px;
}
.bg-testimonial h2 {
    font-size: 48px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: 'Philosopher', sans-serif;
}

.bg-testimonial .title-border {
    background: linear-gradient(to right, #ed1c2400, #ffffff, transparent);
    width: 180px;
}

.bg-testimonial .title-desc {
    max-width: 100%;
    letter-spacing: 3px;
}
.carousel-indicators li {
    width: 50px !important;
    height: 50px !important;
    margin-right: 3px !important;
    margin-left: 3px !important;
    transition: all 1.0s;
  
}
.carousel-indicators .active {
    width: 80px !important;
    height: 80px !important;
}
.pricing-box h5.hotseling {
    border-radius: 0px !important;
    color: #fff;
    line-height: 20px !important;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    width: auto !important;
    height: auto !important;
    padding: 8px 15px !important;
    position: relative;
    top: -36px;
    right: -14px;
    letter-spacing: 1px;
    border-top-left-radius: 13px !important;
    border-top-right-radius: 13px !important;
}
.pricings_boxes {
    max-width: 30.333333%;
    margin: 25px 21px;
}
.pricing-box .textsection a.btn.btn-primary.w-100 {
    font-size: 18px;
    letter-spacing: 1px;
    background: none !important;
    color: #ed1c24 !important;
    font-weight: 700;
}
section#contact .text-muted {
    letter-spacing: 4px;
    font-size: 16px;
}

section#contact h2 {
    font-size: 48px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: 'Philosopher', sans-serif;
}

section#contact .title-border {
    background: linear-gradient(to right, #ed1c2400, #ed1c24, transparent);
    width: 180px;
}
ol.carousel-indicators {
    height: 90px;
    margin-bottom: 0;
}
form#contact-form {
    width: 65%;
    margin: 0 auto;
    letter-spacing: 1px;
}
form#contact-form button#submit {
    letter-spacing: 1px;
    border-radius: 4px;
    font-size: 15px;
    padding: 14px 40px;
}

form#contact-form  input {
    letter-spacing: 1px;
    height: auto !important;
    padding: 20px 20px;
}

form#contact-form textarea.form-control {
    line-height: 50px;
    letter-spacing: 2px;
    height: 190px !important;
}
.footer_location {
    background: #242424;
    padding: 30px 0px;
}
.footer-subscribe {
    border-bottom: 0px solid rgba(255, 255, 255, 0.1) !important;
    clear: both;
    
}
.foterlogo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.footer-social li .mdi:hover {
    color: #801e2f;
}

.footer-social li .mdi {
    font-size: 40px;
    margin: 0px 24px;
    color: #cd2028;
    transition: all 0.6s linear 0s;
}

.footer-subscribe input {
    width: 80%;
    padding: 14px 20px;
    letter-spacing: 3px;
    border: 0px;
}

.footer-subscribe h5.Subscribe {
    color: #fff;
    font-weight: 400;
    letter-spacing: 3px;
   
}

form.mailchimpform {
    display: flex;
}
form#contact-form button#submit:hover {
    background: #b7202a !important;
}
.bogosection .rgt a.btn.btn-primary:hover {
    background: #b1242f00 !important;
    color: #ed1c24 !important;
}
.contactfrom button#submit:hover {
    background: #b1242f00 !important;
    color: #ed1c24 !important;
}
button:focus {
    outline: none !important;
    box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 0%) !important;
}
.bg-progress button.btn.btn-primary:hover {
    background: #b1242f00 !important;
    color: #fff !important;
    border-color: #fff !important;
}
.footer-subscribe button {
    height: auto !important;
    width: 20%;
    letter-spacing: 1px;
    transition: all 0.6s linear 0s;
}
.footer-subscribe button:hover {
    background-color: #931f2c;
}
.footer-social ul.list-inline {
    margin: 0;
}
.contact-info {display: grid;
    grid-template-columns: 54% 42%;
    gap: 4%;
}

.contact-info .mdi {
    font-size: 30px;
    position: relative;
    top: -6px;
}
.contact-info p.text-muted {
    letter-spacing: 1px;
    line-height: 25px;
}
.footerbuttommain {
    display: flex;
    align-items: baseline;
    gap: 5%;
}
.footerbuttommain img {
    width: 216px;
    transition: all 0.6s linear 0s;
}
.footerbuttommain img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.bg-footer {
    background-color: #121212;
    padding: 44px 0px 16px 0px;
}
.footer-alt p {
    letter-spacing: 2px;
    font-style: italic;
}
.foterlogo img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.foterlogo img {
    transition: all 0.6s linear 0s;
    cursor: pointer;
}
.aboutmain {
    margin-top: 5%;
}
.footer-subscribe .msg-alert p {
    margin: 0px;
    letter-spacing: 2px;
   
}
.footer-subscribe .msg-alert {
    position: absolute;
    bottom: -20px;
}
.modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.item-box:hover .item-container {
    width: auto !important;
  }

  .popup_portfolio .modal-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.modal-dialog {
    max-width: 45% !important;
}

.popup_portfolio .modal-title.h4 {
    font-size: 48px;
    letter-spacing: 1px;
    font-weight: 400;
    font-family: "Philosopher", sans-serif;
    width: 100%;
}

.popup_portfolio  .far {margin-right: 1%;}

.popup_portfolio .modal-header {
    padding: 20px 30px;
}

.popup_portfolio .modal-header button.close {
    font-size: 56px;
    font-weight: 100;
    color: #ed1c24;
    opacity: 1;
}

.popup_portfolio .modal-body .right ul li span {
    display: contents;
    font-weight: 800;
}

.popup_portfolio .modal-body .right ul {
    list-style: none;
}

.popup_portfolio .modal-body .right ul li {
    margin: 0px 0px 20px 0px;
    letter-spacing: 2px;
    display: flex;
}

.popup_portfolio .modal-body .right ul li .fal {
    font-size: 25px;
    margin-right: 10px;
    width: 26px;
}
.modal-dialog.book {
    max-width: 100% !important;
    width: 60%;
}
.modal {
    background: #000000d6;
}
@media only screen and (max-width: 1600px){

    .container {max-width: 1140px;}
    .buttomimg img {width: 100%;}
    .buttomimg {width: 20%;}
    .cardimg {width: 80%;margin: 0 auto;}
    .bannercenter h1 {font-size: 30px;margin: 0 auto;text-align: center;}
    .cardimg img {width: 100%;}
    .bannercenter {padding-top: 16%;}
    .about-box {padding: 40px 50px !important;}
    .about-box h5 {font-size: 18px !important;}
    .becometext h2 {font-size: 24px;}
    .contactfrom h1.title {font-size: 22px;}
    .contactfrom input {padding: 10px 12px;}
    .progress-content {height: 220px !important;}
    .bogosection .rgt h4 {font-size: 19px;}
    .bogosection .lft h2 {font-size: 24px;}
    .pricings_boxes {max-width: 33.333333%;margin: 25px 0px;}
    .pricing-box .textsection h5 {font-size: 33px;}
    .pricing-box .pricing-features p img {width: 10px;margin-right: 8px;}
    .pricing-box .textsection {padding: 30px 16px;}
    .portfoliobox {margin: 6px 5px;}
    .nav-sticky.navbar-custom.sticky-dark button.navbar-toggler {color: #000;}
    .footerbuttommain img {width: 167px;}
    .footer-subscribe button {width: 30%;}
    .footer-subscribe input {width: 70%;}

}
@media only screen and (max-width: 820px){

    button.navbar-toggler {position: absolute;right: 0;color: #fff;font-size: 40px;z-index: 999999999;}
    .navbar-collapse {position: absolute;top: 51px;left: 0;right: 0;width: 100%;background: #000000;padding: 0px 30px;}
    .home-section .topimg {width: 16%;top: 30px;}
    .home-section .topimg img {width: 100%;}
    .blog-img {height: 330px;}
    section#blog .col-lg-4 {width: 33%;}
    .blog_text h5 {font-size: 20px;height: 44px!important;}
    .section {padding-top: 46px;}
    .benefits .col-lg-4 {width: 50%;}
    .aboutmain .becometext {max-width: 45.333333%;    }
    .aboutmain .contactfrom {flex: 1 0 41.6666666667%;max-width: 60.666667%;}
    .contactfrom h1.title {font-size: 21px;}
    .bg-progress .col-lg-3 {width: 50%;margin-bottom: 20px;}
    .bogosection .container {display: block;}
    .bogosection .lft {width: 100%;}
    .bogosection .rgt {width: 100%;justify-content: flex-end;}
    .pricing-box .textsection h5 {font-size: 24px;}
    .pricing-box .textsection h1 {font-size: 28px !important;}
    .portfoliobox {margin: 6px 4px;}
    .cbox-gallary1 img {width: 160px;height: 160px;margin-bottom: 16px;}
    .footer_location .col-lg-7 {width: 50%;}
    .footer-social li .mdi {font-size: 30px;margin: 0px 10px;}
    .footer_location .col-lg-5 {width: 50%;}
    .footer-subscribe input {width: 70%;}
    .footer-subscribe button {width: 30%;}
    .footer-subscribe h5.Subscribe {letter-spacing: 1px;}
    .footerbuttommain {justify-content: center;}
    .pricing-box .textsection a.btn.btn-primary.w-100 {font-size: 16px;}
    .contactfrom select.form-control {height: 50px;}
    .home-section {height: 100%;position: relative;    }
    .bannercenter {padding-top: 38%;padding-bottom: 18%;}
    .modal-dialog {
        max-width: 90% !important;
    }
    .modal-dialog.book {
      
        width: 94%;
    }

}

@media only screen and (max-width: 480px){

    button.navbar-toggler {color: #000;}    
    .navbar-custom {padding: 0px 0px !important;}
    .nav-sticky .logo .logo-dark {width: auto;}
    div#navbarCollapse li a {color: #ffffff !important;}
    .bannercenter {padding-top: 54%;padding-bottom: 23%;}
    .home-section .topimg {width: 24%;top: 30px;}
    .buttomimg {width: 30%;}
    .bannercenter h1 {height: auto;overflow: inherit;    padding: 0px 30px;}
    .bannercenter h1 img {display: block;text-align: center;margin: 0 auto;}
    .bannercenter h1 strong {font-size: 30px;display: block;}
    section#blog h2 {font-size: 28px;}
    .title-border {width: 80px !important;}
    section#blog .col-lg-4 {width: 100%;}
    .blogmain {margin-top: 1rem !important;}
    .blog_text h5 {height: auto!important;}
    .benefits .text-muted {font-size: 14px;letter-spacing: 3px;}
    .benefits h2 {font-size: 26px;}
    .benefits .col-lg-4 {width: 100%;}
    .aboutboxmain {margin-top: 0rem !important;}
    .aboutmain .becometext {max-width: 100%;}
    .aboutmain .contactfrom {max-width: 100%;}
    #slider-booking-form {margin-top: 0px !important;width: 100% !important;}
    .becometext h2 {font-size: 20px;line-height: 30px;}
    .about-box h5 {height: auto;}
    .about-box {padding: 40px 20px !important;height: auto !important;}
    .aboutmain {margin-top: 12%;}
    .becometext p.title-desc {font-size: 14px !important;line-height: 28px;margin-bottom: 20px;}
    .contactfrom {padding: 30px 20px 20px 20px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;}
    .contactfrom h1.title {font-size: 25px !important;}
    .contactfrom button#submit {float: none;width: 97%;margin: 0px 6px;}
    .bg-progress h2 {font-size: 30px;}
    .bg-progress .col-lg-3 {width: 100%;}
    .bg-progress button.btn.btn-primary {top: 10px;}
    .bg-progress {padding-bottom: 50px;}
    .progress-content {height: auto !important;}
    .section {padding-top: 46px;padding-bottom: 46px;}
    .ProcessBoxmain {margin-top: 1rem !important;}
    .bg-progress .progress-count h3 {font-size: 30px;width: 90px;height: 90px;}
    .progress-content:before {width: 18px;height: 18px;}
    .progress-content h5 {margin-top: 1rem !important;    }
    .about-box i {font-size: 45px !important;}
    .bogosection .lft img {float: none;width: 99px;display: table;margin: 0 auto !important;padding-bottom: 16px;}
    .bogosection .lft {text-align: center;}
    .bogosection .rgt {display: block;text-align: center;}
    .pricing .text-muted {letter-spacing: 2px;}
    .pricing h2 {font-size: 38px;}
    .pricing .title-desc {font-size: 14px !important;margin-top: 0rem !important;}
    .pricings_boxes {max-width: 100%;margin: 0px 0px 30px 0px;}
    .pricing-box .textsection h5 {font-size: 30px;}
    .pricing-box .textsection h1 {font-size: 28px !important;margin: 0px !important;}
    .pricing-box .pricing-features p img {width: 10px;height: 10px;margin: 6px 10px 0px 0px;}
    .pricing-box .pricing-features p {padding-bottom: 6px;display: flex;}
    .bogosection .rgt h4 {margin-bottom: 10px;}
    .pricing-box .textsection {padding: 16px 16px;}
    .pricing-box .textsection .mt-5 {margin-top: 0rem !important;}
    .pricing-box h5.hotseling {top: -17px;}
    section#portfolio h2 {font-size: 27px;}
    section#portfolio .title-desc {font-size: 14px !important;}
    .portfoliobox {margin: 6px 4px;max-width: 100%;}
    .carousel-indicators li {height: 37px !important;}
    .carousel-indicators .active {height: 58px !important;}
    .bg-testimonial h2 {font-size: 36px;}
    .testimonialcenter {margin-top: 1rem !important;}
    .testimonial-box .testimonial-title {max-width: 100% !important;font-size: 14px !important;}
    .testimonial-box {margin-top: 0 !important;}
    section#contact h2 {font-size: 25px;}
    section#contact .text-muted {letter-spacing: 3px;font-size: 14px;}
    form#contact-form {width: 90%;margin: 0 auto;letter-spacing: 1px;    }
    .footer_location .col-lg-7 {width: 100%;}
    .footer_location .col-lg-5 {width: 100%;}
    .foterlogo {display: block;justify-content: center;align-items: center;text-align: center;}
    .footer-social li .mdi {font-size: 40px;margin: 0px 22px;}
    .foterlogo img {margin-bottom: 10px;}
    .contact-info {display: grid;grid-template-columns: 100%;grid-gap: 0;gap: 2%;}
    .footerbuttommain {justify-content: flex-start;margin: 16px 0px;}
    .footerbuttommain img {width: 140px;}
    .footer-alt p {letter-spacing: 1px;font-size: 14px;}
    .port.portfolio-masonry {margin-top: -2rem !important;}
    .modal-dialog {
        max-width: 90% !important;
    }
    .popup_portfolio .modal-body {
        display: block;
    }
    .popup_portfolio .modal-title.h4 {
        font-size: 26px;
    
    }
    .popup_portfolio .modal-header button.close {
        font-size: 30px;
    }
    .popup_portfolio .modal-body img.item-container {
        width: 50%;
        display: table;
        margin: 0 auto;
    }
    button.navbar-toggler {
        position: absolute;
        right: 0;
        color: #fff;
        font-size: 40px;
        z-index: 999999999;
    }

}