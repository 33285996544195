//
// home.scss
//

.bg-home {
    background-image: url(../images/bg-1.jpg);
  background-size: cover;
  position: relative;
  height: 100vh;
  background-position: center;
}

.home-center {
    display: table;
    width: 100%;
    height: 100%;
}

.home-desc-center {
    display: table-cell;
    vertical-align: middle;
}

.home-content {
    .play-icon-circle {
        height: 66px;
        width: 66px;
        background-color: $white;
        line-height: 66px;
        text-align: center;
        border-radius: 50%;
        display: inline-block;
        transition: all 0.4s;
        color: #ed1c24;
        &:hover{
            background-color: #ed1c24;
            color: $white;
        }
    }
    .sub-title{
        border-bottom: 2px solid $white;
        padding-bottom: 6px;
        display: inline-block;
        font-size: 20px;
        letter-spacing: 1px;;
    }
    .title{
        font-size: 62px;;
    }
}



.home-section {
    .carousel-item {
        height: 100vh;
        background-size: cover;
        background-position: center center;
    }
}

.carousel-control-next,
.carousel-control-prev {
    width: 5%;
}

// home-2

.home-half {
    padding: 210px 0 210px 0;
    height: auto;
    position: relative;
}

// home-3

.home-full{
    height: 100vh;
    background-size: cover;
    position: relative;
    background-position: center;
}


// home-4
.slidero {
    position: relative;
    z-index: 1;
}


.search-form {
    input {
        padding: 15px 20px;
        width: 100%;
        font-size: 17px;
        color: $muted !important;
        border: none;
        outline: none !important;
        padding-right: 160px;
        padding-left: 30px;
        background-color: rgba(255, 255, 255, 0.85);
        border-radius: 30px;
        font-weight: 500;
    }
    button {
        position: absolute;
        top: 5px;
        right: 6px;
        outline: none !important;
        border-radius: 30px;
        font-size: 17px;
        padding: 9px 30px;
    }
    form {
        position: relative;
        max-width: 550px;
    }
    
}

// home-6

.registration-form {
    .form-control {
      &:focus {
        outline: 0;
        box-shadow: none;
        border-color: $primary;
      }
    }
  }
